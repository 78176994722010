/**
 * This file contains all the dependancies library needed
 * 
 * It is used for the library that we don't wish to include in webpack compilation
 */

var CDN_URL = "https://cdn.rawgit.com/aetna-softwares" ;
var VERSIONS = {
    view : "8beee9d27163fd828897f9631f393f72887ef880",
    loader: "c53d798e",
    controller: "82d7b847235fb72dd83150dd3ea41ceca2f1c68b"
} ;

module.exports = [
    { name: "velox-web-view",
        type: "js",
        version: "0.0.1",
        cdn: "https://cdn.rawgit.com/aetna-softwares/velox-view/master/velox-web-view.js",
        npmPath: "velox-view/velox-web-view.js"
    },
    [
        { name: "velox-web-view-ext-i18n", 
            type: "js",
            version: "0.0.1",
            cdn: "https://cdn.rawgit.com/aetna-softwares/velox-view/master/ext/velox-i18next.js",
            npmPath: "velox-view/ext/velox-i18next.js"
        },
        { name: "velox-web-view-ext-bootstrap4-dialogs",
            type: "js",
            version: "0.0.1",
            cdn: "https://cdn.rawgit.com/aetna-softwares/velox-view/master/ext/velox-bootstrap4-dialogs.js",
            npmPath: "velox-view/ext/velox-bootstrap4-dialogs.js"
        },
        { name: "velox-web-view-ext-fields",
            type: "js",
            version: "0.0.1",
            cdn: "https://cdn.rawgit.com/aetna-softwares/velox-view/master/ext/velox-fields.js",
            npmPath: "velox-view/ext/velox-fields.js"
        },
        { name: "velox-web-view-ext-fields-schema",
            type: "js",
            version: "0.0.1",
            cdn: "https://cdn.rawgit.com/aetna-softwares/velox-view/master/ext/velox-fields-schema.js",
            npmPath: "velox-view/ext/velox-fields-schema.js"
        },
        // { name: "velox-web-view-ext-list", //Use if you need list input (like invoice lines)
        //     type: "js",
        //     version: "0.0.1",
        //     cdn: "https://cdn.rawgit.com/aetna-softwares/velox-view/master/ext/velox-list.js",
        //     npmPath: "velox-view/ext/velox-list.js"
        // },
        { name: "velox-web-view-ext-user-rights", //Use if you need some user right managment in views
            type: "js",
            version: "0.0.1",
            cdn: "https://cdn.rawgit.com/aetna-softwares/velox-view/master/ext/velox-user-rights.js",
            npmPath: "velox-view/ext/velox-user-rights.js"
        }
    ],
    [
        { name: "velox-controller",
            type: "js",
            version: VERSIONS.controller,
            cdn: CDN_URL+"/velox-controller/$VERSION/velox-app-controller.js",
            npmPath: "velox-controller/velox-app-controller.js"
        },
        { name: "velox-controller-view",
            type: "js",
            version: VERSIONS.controller,
            cdn: CDN_URL+"/velox-controller/$VERSION/velox-view-controller.js",
            npmPath: "velox-controller/velox-view-controller.js"
        }
    ],
    [
        { name: "velox-controller-grid", //Use if you need a grid screen
            type: "js",
            version: VERSIONS.controller,
            cdn: CDN_URL+"/velox-controller/$VERSION/velox-form-controller.js",
            npmPath: "velox-controller/velox-form-controller.js"
        },
        {  name: "velox-controller-form", //Use if you need a form screen
            type: "js",
            version: VERSIONS.controller,
            cdn: CDN_URL+"/velox-controller/$VERSION/velox-grid-controller.js",
            npmPath: "velox-controller/velox-grid-controller.js"
        }
    ],
    [
        { name: "velox-controller-grid-and-form", //Use if you need a CRUD grid+form screens
            type: "js",
            version: VERSIONS.controller,
            cdn: CDN_URL+"/velox-controller/$VERSION/velox-grid-and-form-controller.js",
            npmPath: "velox-controller/velox-grid-and-form-controller.js"
        }
    ],
    { name: "velox-service-client", //Use if you need to talk to a server
        type: "js",
        version: "0.0.1",
        cdn: "https://cdn.rawgit.com/aetna-softwares/velox-service-client/master/velox-service-client.js",
        npmPath: "velox-service-client/VeloxServiceClient.js"
    },
    { name: "velox-database-client", //Use if you server part use database
        type: "js",
        version: "0.0.1",
        cdn: "https://cdn.rawgit.com/aetna-softwares/velox-database-client/master/client/src/VeloxDatabaseClient.js",
        npmPath: "velox-database-client/client/src/VeloxDatabaseClient.js"
    },
    // { name: "velox-offlinesync-client", //Use if you need offline sync
    //     type: "js",
    //     version: "0.0.1",
    //     cdn: "https://cdn.rawgit.com/aetna-softwares/velox-database-client/master/client/src/ext/VeloxDbOfflineSync.js",
    //     npmPath: "velox-database-client/client/src/ext/VeloxDbOfflineSync.js"
    // },
    { name: "velox-usermanagment-client", //Use if you manage user rights
        type: "js",
        version: "0.0.1",
        cdn: "https://cdn.rawgit.com/aetna-softwares/velox-database-client/master/client/src/VeloxUserManagmentClient.js",
        npmPath: "velox-database-client/client/src/VeloxUserManagmentClient.js"
    },
    { name: "velox-crashreport-client", //Use if you need crash reports
        type: "js",
        version: "0.0.1",
        cdn: "https://cdn.rawgit.com/aetna-softwares/velox-database-client/master/client/src/VeloxCrashReportClient.js",
        npmPath: "velox-database-client/client/src/VeloxCrashReportClient.js"
    },
    { name: "velox-binarystorage-client", //Use if you need some binary attachments
        type: "js",
        version: "0.0.1",
        cdn: "https://cdn.rawgit.com/aetna-softwares/velox-database-client/master/client/src/VeloxBinaryStorageClient.js",
        npmPath: "velox-database-client/client/src/VeloxBinaryStorageClient.js"
    },
    
    [ 
        { name: "moment", //Date manipulation
            type: "js",
            version: "2.22.1",
            cdn: "https://cdnjs.cloudflare.com/ajax/libs/moment.js/$VERSION/moment.js",
            npmPath: "moment/min/moment-with-locales.min.js"
        },
        { name: "decimaljs-light", //Number manipulation (operation)
            type: "js",
            version: "2.2.0",
            cdn: "https://cdn.jsdelivr.net/gh/MikeMcl/decimal.js-light@$VERSION/decimal.min.js",
            bowerPath: "decimal.js-light/decimal.min.js",
            npmPath: "decimal.js-light/decimal.min.js",
        },
        { name: "numbro", //number format
            type: "js",
            version: "2.0.6",
            cdn: "https://cdnjs.cloudflare.com/ajax/libs/numbro/$VERSION/numbro.min.js",
            bowerPath: "numbro/dist/numbro.min.js",
            npmPath: "numbro/dist/numbro.min.js"
        },
        { name: "numbro-language",  //number format (langs)
            type: "js",
            version: "2.0.6",
            cdn: "https://cdnjs.cloudflare.com/ajax/libs/numbro/$VERSION/languages.min.js",
            bowerPath: "numbro/dist/languages.min.js",
            npmPath: "numbro/dist/languages.min.js"
        }
    ],

    [
        { name: "jquery", //bootstrap 4 dep
            type: "js",
            version: "3.3.1",
            cdn: "http://code.jquery.com/jquery-$VERSION.min.js",
            npmPath: "jquery/dist/jquery.min.js"
        },
        { name: "popper.js",  //bootstrap 4 dep
            type: "js",
            version: "1.14.3",
            cdn: "hhttps://cdnjs.cloudflare.com/ajax/libs/popper.js/$VERSION/umd/popper.min.js",
            npmPath: "popper.js/dist/umd/popper.min.js"
        }
    ],
    [
        {name: "bootstrap-css", //bootstrap 4
            type: "css",
            version: "4.1.1",
            cdn: "https://maxcdn.bootstrapcdn.com/bootstrap/$VERSION/css/bootstrap.min.css",
            npmPath : "bootstrap/dist/css/bootstrap.min.css"
        },
        {name: "bootstrap-js", //bootstrap 4
            type: "js",
            version: "4.1.1",
            cdn: "https://maxcdn.bootstrapcdn.com/bootstrap/$VERSION/js/bootstrap.min.js",
            npmPath : "bootstrap/dist/js/bootstrap.min.js"
        },
        {name: "fontawesome", //icons fontawesome
            type: "css",
            version: "5.0.12",
            cdn: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/$VERSION/css/font-awesome.min.css",
            npmPath : "@fortawesome/fontawesome-free-webfonts/css/fontawesome.css"
        },
        {name: "fontawesome-solid", //icons fontawesome
            type: "css",
            version: "5.0.12",
            cdn: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/$VERSION/css/fa-solid.css",
            npmPath : "@fortawesome/fontawesome-free-webfonts/css/fa-solid.css"
        },
        {name: "fontawesome-brands", //icons fontawesome
            type: "css",
            version: "5.0.12",
            cdn: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/$VERSION/css/fa-brands.css",
            npmPath : "@fortawesome/fontawesome-free-webfonts/css/fa-brands.css"
        },
        {name: "fontawesome-regular", //icons fontawesome
            type: "css",
            version: "5.0.12",
            cdn: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/$VERSION/css/fa-regular.css",
            npmPath : "@fortawesome/fontawesome-free-webfonts/css/fa-regular.css"
        },
        {name : "chosen-css", //bootstrap CSS for chosen select
            type: "css",
            version: "1.0.8",
            cdn: "https://cdn.jsdelivr.net/npm/bootstrap4c-chosen@$VERSION/dist/css/bootstrap4c-chosen.min.css",
            npmPath : "bootstrap4c-chosen/dist/css/component-chosen.min.css"
        }
    ],
    [
        {name: "bootstrap-theme",
            type: "css",
            version: "1.0.0",
            localPath: "css/theme.css",
        }
    ]
] ;