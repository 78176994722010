/*global $, veloxScriptLoader, VeloxWebView, VeloxServiceClient, VeloxViewController, VeloxFormController, VeloxAppController, VeloxGridAndFormController, mocha, VeloxDatabaseClient */

var offlineRuntime = require('offline-plugin/runtime');

//hide splash
var splashcreen = document.getElementById("loader") ;

offlineRuntime.install({
    onUpdating: function() {
      console.log('SW Event:', 'onUpdating', splashcreen.className);
      splashcreen.className += " updating" ;
    },
    onUpdateReady: function() {
      console.log('SW Event:', 'onUpdateReady');
      // Tells to new SW to take control immediately
      offlineRuntime.applyUpdate();
    },
    onUpdated: function() {
      console.log('SW Event:', 'onUpdated');
      // Reload the webpage to load into the new version
      window.location.reload();
    },
  
    onUpdateFailed: function() {
      console.log('SW Event:', 'onUpdateFailed', splashcreen.className);
      splashcreen.className.replace(/updating/g, "") ;
    },
    onInstalled:function() {
      console.log('SW Event:', 'onInstalled', splashcreen.className);
    }
});

var libs = require("./config/libs") ;
var libsDatatables = require("./config/libsDatatables") ;
var pkg = require("../package.json") ;
//

veloxScriptLoader.setOptions({
    policy: "npm",
    npmPath: "node_modules"
}) ;


var libsToLoad = libs ;
if(process.env.MODE === "development"){
    var libsTest = require("./config/libsTest") ;
    libsToLoad = libsToLoad.concat(libsTest);
}


veloxScriptLoader.addLoadListener("chosen-css", function(done){
    //temp fix https://github.com/haubek/bootstrap4c-chosen/issues/5
    veloxScriptLoader.loadCssCode('.chosen-container-single .chosen-single input[type="text"] {'+
    '    cursor: pointer;'+
    '    opacity: 0;'+
    '    position: absolute;'+
    '    width: 0;'+
    '}'+
    '.was-validated .form-control:invalid ~ .chosen-container>a {    border-color: #da291c;}') ;
    done() ;
}) ;


veloxScriptLoader.load(libsToLoad, function(err){
    if(err){ return alert("Can't load view library "+JSON.stringify(err)) ;}


    

    //Bootstrap 4 extension (should put in external extension)
    VeloxWebView.registerExtension({
        name: 'bootstrap-4',
        init: function(){
            var view = this;

            
            var elScrollSpyNavsIds = [] ;

            view.on("render", function(){
                //init boostrap spy if any
                var spyContents = view.viewRootEl.querySelectorAll("[data-spy]") ;
                for(var i=0; i<spyContents.length; i++){
                    if(!spyContents[i].alreadyAddedSpy){
                        elScrollSpyNavsIds.push(spyContents[i].getAttribute("data-target")) ;
                        $(spyContents[i]).scrollspy({ target: spyContents[i].getAttribute("data-target") }) ;
                        spyContents[i].alreadyAddedSpy = true;
                    }else{
                        $(spyContents[i]).scrollspy('refresh') ;
                    }
                }
                elScrollSpyNavsIds.forEach(function(id){
                    //prevent click on href as it will disturb controller navigation and do manual scroll instead
                    $(id+" a").each(function(i, a){
                        if(!a.alreadyAddedSpyListener){
                            a.addEventListener("click", function(ev){
                                document.getElementById(a.getAttribute("href").substring(1)).scrollIntoView() ;
                                ev.preventDefault();
                            }) ;
                            a.alreadyAddedSpyListener = true;
                        }
                    }) ;
                }) ;

                //init tooltips
                $(view.viewRootEl).find('[data-toggle="tooltip"]').tooltip() ;
            });
        }
    });

    var objectsToInject = {} ;

    var confCalls = [] ;

    var i18nConfiguration = require("./config/i18n") ;
    if(i18nConfiguration){
        confCalls.push(function(cb){
            VeloxWebView.i18n.configure(i18nConfiguration, cb) ;
        }) ;
    }


    var apiConfiguration = require("./config/apiConfig") ;
    if(apiConfiguration){
        confCalls.push(function(cb){
            var api = new VeloxServiceClient(apiConfiguration) ;

            objectsToInject.api = api ;
    
            api.addEndPoints(require("./config/endpoints.js")) ;
    
            window.addEventListener("error", function (error) {
                var msg = "";
                try{
                    msg = error.message ;
                    if(error.error){
                        var stack = error.error.stack ;
                        if(stack){
                            msg += "\n"+stack ;
                        }
                    }
                    var unexpectedErrorMsg = VeloxWebView.tr("global.unexpectedError") ;
                    VeloxWebView.error(unexpectedErrorMsg+"\n"+msg) ;
                    
                }catch(err){
                    console.log("error while display unexpected error message...", err, error) ;						
                }
                try{
                    var report = {
                        app_name : pkg.name,
                        app_version: pkg.version,
                        date : new Date(),
                        user_agent: window.navigator.userAgent,
                        url: window.location.href,
                        error: msg
                    } ;
                    api.saveCrashReport(report, function(err){
                        if(err){
                            console.log("error while sending crash report") ;
                        }
                    }) ;
                }catch(err){
                    console.log("error while sending crash report...", err, error) ;						
                }
                return false;

            }) ;


            //init api
            api.init(function(err){
                if(err){ 
                    return VeloxWebView.error(err);
                }
    
                
                api.api.getSchema(function(err, schema){
                    if(err){ 
                        return VeloxWebView.error(err);
                    }

                    if(VeloxWebView.fieldsSchema){
                        VeloxWebView.fieldsSchema.configure({
                            schema: schema, 
                        //    schemaExtend: schemaExtends,
                            addLabelToFields : true,
                            addErrorsToFields : true
                        }) ;
                    }

                    if(VeloxWebView.fields){
                        VeloxWebView.fields.configure({
                            apiClient: api, 
                        }) ;
                    }


                    cb() ;
                }) ;
            }) ;    
        }) ;
    }

    VeloxWebView._asyncSeries(confCalls, function(err){
        if(err){ 
            return VeloxWebView.error(err);
        }

        VeloxViewController.setDefaultContainerParent("views") ;

        //load the bootstrap theming for datatable after datatable file are loaded
        veloxScriptLoader.addLoadListener("datatables-select-js", function(done){
            veloxScriptLoader.load(libsDatatables, function(err){
                if(err){ return done(err) ;}
                window.jQuery.extend( true, window.jQuery.fn.dataTable.Buttons.defaults, {
                    dom: {
                            container: {
                                className: 'btn-group mr-2'
                            },
                            button: {
                                    className: 'btn btn-sm btn-outline-secondary'
                            },
                            collection: {
                                    tag: 'div',
                                    className: 'dt-button-collection dropdown-menu',
                                    button: {
                                            tag: 'a',
                                            className: 'dt-button dropdown-item',
                                            active: 'active',
                                            disabled: 'disabled'
                                    }
                            }
                    }
                } );
                window.jQuery.extend( true, window.jQuery.fn.dataTable.defaults, {
                    language: {
                        buttons: {
                            colvis: '&nbsp;<i class="fas fa-bars"></i>',
                            copy: '<i class="far fa-copy"></i>',
                            excel: '<i class="far fa-file-excel"></i>',
                            pdf:'<i class="far fa-file-pdf"></i>',
                            print:'<i class="fas fa-print"></i>'

                        }
                    },
                    dom:
                        "<'row'<'col-xs-12 col-sm-12 col-md-6 d-flex flex-row'lB><'col-xs-12 col-sm-12 col-md-6 mt-2'f>>" +
                        "<'dt-table'tr>" +
                        "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                } );
                done();
            }) ;
        }) ;

        VeloxWebView.fields.addDecorator(function bootstrap4decorator(element, fieldType){
            if(fieldType === "select" || fieldType === "selection"){
                var select = element.getElementsByTagName("select")[0] ;
                select.classList.add("form-group") ;
                select.classList.add("form-control") ;
                select.classList.add("form-control-chosen") ;
                return ;
            }
            if(fieldType === "textarea"){
                var textarea = element.getElementsByTagName("textarea")[0] ;
                element.className += " form-group" ;
                textarea.classList.add("form-control") ;
                return ;
            }
            if(fieldType === "upload" || fieldType === "pdf"){
                return;
            }
            if(fieldType === "grid" ){
                var tables = element.getElementsByTagName("table") ;  
                for(var i=0; i<tables.length; i++){
                    tables[i].className += " table table-striped table-bordered table-hover dt-responsive nowrap" ;
                }
                var refreshButton = element.querySelector(".table-custom-button-refresh") ;
                if(refreshButton){
                    refreshButton.innerHTML = '&nbsp;<i class="fas fa-sync"></i>&nbsp;' ;
                }
                var createButton = element.querySelector(".table-custom-button-createNew") ;
                if(createButton){
                    createButton.className = createButton.className.replace("btn-outline-secondary", "btn-primary") ;
                }
                var uploadButton = element.querySelector(".table-custom-button-uploadFile") ;
                if(uploadButton){
                    uploadButton.className = uploadButton.className.replace("btn-outline-secondary", "btn-primary") ;
                }
                return;
            }
            var input = element.getElementsByTagName("input")[0] ;
            if(input){
                if(input.type === "checkbox"){
                    if(fieldType !== "toggle"){
                        element.className += " form-check" ;
                        var label = element.getElementsByTagName("label")[0] ;
                        if(label){
                            label.className += " form-check-label" ;
                        }
                        input.className += " form-check-input";
                    }
                }else{
                    element.className += " form-group" ;
                    input.className += " form-control" ;
                }
            }else{
                element.className += " form-group" ;
                
            }
            
            
        }) ;

        if(VeloxFormController){
            VeloxFormController.setOptions({
                buttonsHTML: require("./views/global/form-buttons.html")
            }) ;
        }

        // var loginController = require("./controllers/loginController") ;

        var app = new VeloxAppController() ;
        app.context = { controllers: {}} ;
        Object.keys(objectsToInject).forEach(function(k){
            app.context[k] = objectsToInject[k] ;
            app.injectToControllers(k, objectsToInject[k]) ;
        }) ;

        var requireControllers = require.context("./controllers", true, /^\.\/.*\.js$/) ;

        requireControllers.keys().forEach(function(c){
            var controller = requireControllers(c);
            app.context.controllers[c.match(/([a-zA-Z0-9]+)\.js$/m)[1]] = controller ;
            app.registerController(controller) ;
        });

        var requireExtensions = require.context("./extensions", true, /^\.\/.*\.js$/) ;

        requireExtensions.keys().forEach(function(c){
            var extension = requireExtensions(c);
            VeloxWebView.registerExtension(extension);
        });


        var callsStartup = [] ;

        var requireInterceptors = require.context("./interceptors", true, /^\.\/.*\.js$/) ;

        requireInterceptors.keys().forEach(function(c){
            var interceptors = requireInterceptors(c);
            interceptors.forEach(function(interceptor){
                if(interceptor.type === "ajax"){
                    app.context.api.addAjaxInterceptor(function(err, request, response, next){
                        interceptor.interceptor(app, err, request, response, next) ;
                    }) ;
                }else if(interceptor.type === "navigation"){
                    app.addInterceptor(function(currentPosition, next){
                        interceptor.interceptor(app,currentPosition, next) ;
                    }) ;
                }else if(interceptor.type === "startup"){
                    callsStartup.push(function(cb){
                        interceptor.interceptor(app, cb) ;
                    }) ;
                }else{
                    VeloxWebView.error("unkown interceptor type "+interceptor.type);
                }
            }) ;
        });

        VeloxWebView._asyncSeries(callsStartup, function(err){
            if(err){ 
                return VeloxWebView.error(err);
            }

            //hide splash
            splashcreen.addEventListener("transitionend", function(){
                splashcreen.className += " hide-done" ;
                setTimeout(function(){
                    splashcreen.parentElement.removeChild(splashcreen);
                }, 2000) ;
            }) ;
            splashcreen.className += " hide" ;

            app.navigate() ;
            if(process.env.MODE === "development"){
                var runTest = null;
                if(window.location.search){
                    var params = window.location.search.substring(1).split("&") ;
                    params.forEach(function(paramPair){
                        var param = paramPair.split("=") ;
                        if(param[0] === "runTest"){
                            runTest = param[1] ;
                        }
                    });
                }
                if(runTest){
                    var divMocha = document.createElement("DIV") ;
                    divMocha.id = "mocha" ;
                    divMocha.style.position = "absolute" ;
                    divMocha.style.top = 0 ;
                    divMocha.style.bottom = 0 ;
                    divMocha.style.left = 0 ;
                    divMocha.style.right = 0 ;
                    divMocha.style.backgroundColor = "rgba(255, 255, 255, 0.81)" ;
                    divMocha.style.display = "none" ;
                    document.body.appendChild(divMocha) ;
                    window.controllers = {} ;
                    app.controllers.forEach(function(ct){
                        if(ct.viewOptions){
                            window.controllers[ct.viewOptions.name] = ct ;
                        }else if (ct instanceof VeloxGridAndFormController){
                            window.controllers[ct.table] = ct ;
                        }
                    }) ;
                    mocha.setup('bdd') ;
                    var testsToRun = [] ;
                    if(runTest === "all"){
                        var requireTests = require.context("../tests", true, /^\.\/.*\.js$/) ;

                        testsToRun = requireTests.keys().map(function(t){ return "tests/"+t.replace(/^\.\//, "") ;}) ;
                    }else{
                        testsToRun = runTest.split(",").map(function(t){ return "tests/"+t+".js" ;}) ;
                    }
                    veloxScriptLoader.load(testsToRun.map(function(t){
                        return {
                            name: t,
                            type: "js",
                            version: 1,
                            localPath: t
                        } ;
                    }), function(){
                        mocha.checkLeaks();
                        mocha.globals(["JSZip", "__core-js_shared__", "pdfMake", "createPdf", "Inputmask"]) ;
                        mocha.run(function(){
                            console.log("finished") ;
                            divMocha.style.display = "block" ;
                            var mochaStats = divMocha.querySelector("#mocha-stats") ;
                            if(mochaStats){
                                mochaStats.style.zIndex = 9999;
                                mochaStats.style.backgroundColor = "white";
                            }
                        });
                    });
                }
            }
        });
                
        


    }) ;
}) ;