/**
 * Get the API server configuration
 */

var pkg = require("../../package.json") ;
var baseUrl = pkg.serverUrl ;

if(process.env.MODE !== "production"){
    baseUrl = pkg.serverDev ;
    if(process.env.DEVICE === "mobile"){
        baseUrl = pkg.serverDevMobile ;
    }
}

//var apiMock = require("../mocks/apiMock") ;

//set to false for no API server
module.exports = {
    serverUrl : baseUrl,
} ;