/*global VeloxGridController, VeloxWebView*/

var upload = require("../../util/upload") ;
var uuid = require("uuid") ;

/**
 * This is a basic MVC grid/form controller
 * 
 * It creates 2 routes : #ocr_doc/list and #ocr_doc/form
 */
var ocrDocController = new VeloxGridController("ocr_doc", {
    directory: "views/ocrDoc",
    name: "ocrDocGrid",
    canCreate: false,
    canRefresh: true,
    joinFetch: [
        { otherTable: "velox_binary", name: "binary", thisField: "od_uuid", otherField:"table_uid" , joinSearch: {"table_name": "ocr_doc"}}
    ],
    route: ""
});

ocrDocController.onRetry = function(ev){
    this.view.longTask(function(done){
        var changes = [
            {table: "ocr_doc", record : {
                od_uuid: ev.data.data.od_uuid, 
                od_status: "TODO"
            }, action: "update"},
            {table: "ocr_word", record : {
                od_uuid: ev.data.data.od_uuid, 
            }, action: "removeWhere"},
            {table: "ocr_page", record : {
                od_uuid: ev.data.data.od_uuid, 
            }, action: "removeWhere"},
        ] ;
        this.api.api.transactionalChanges(changes, done) ;
    }.bind(this), function(err){
        if(!err){
            this.refresh() ;
        }
    }.bind(this));
} ;

ocrDocController.onDownloadPdf = function(ev){
    var doc = ev.data.data ;
    this.view.longTask(function(done){
        this.api.readBinary.download(doc.binary, done) ;
    }.bind(this));
} ;

ocrDocController.onDownloadResult = function(ev){
    var filename = ev.data.data.od_filename.replace(/pdf$/i, "csv") ;
    var blob = new Blob([ev.data.data.od_result], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
} ;

ocrDocController.onUploadFile = function(){
    upload({multiple: true, accept: ".pdf"},function(files){
        var calls = [] ;

        files.forEach(function(file){
            calls.push(function(cb){
                var docUid = uuid.v4() ;
                this.api.saveBinary(
                    file,
                    {
                    table_name: "ocr_doc", 
                    table_uid: docUid, 
                    mime_type: file.type,
                    description : file.name,
                    filename: file.name
                }, function(err){
                    if(err){ return cb(err); }
                    var changes = [
                        {table: "ocr_doc", record : {
                            od_uuid: docUid, 
                            od_filename: file.name, 
                            od_receive_date: new Date(),
                            od_receive_channel: "UPLOAD",
                            od_status: "TODO"
                        }, action: "insert"}
                    ] ;
                    this.api.api.transactionalChanges(changes, cb) ;
                }.bind(this)) ;
            }.bind(this));
        }.bind(this));


        this.view.longTask(function(done){
            VeloxWebView._asyncSeries(calls, function(err){
                if(err){ return done(err) ;}
                this.refresh(done) ;
            }.bind(this));
        }.bind(this));
    }.bind(this));
} ;


module.exports = ocrDocController ;