/*global VeloxWebView*/

/**
 * This interceptors deals with user session management
 *  - ajax interceptor do redirect to login on 401 responses
 *  - navigation interceptor to redirect to login if no session opened
 *  - startup interceptor to refresh user session on application startup
 */
module.exports = [
    {   
        type: "ajax", //called on ajax responses
        interceptor: function (app, err, request, response, next) {
            if ( response &&
                (
                (response.status === 401 && response.url !== "auth/user") || //no session error
                (response.url === "refreshUser?" && response.response.login === "anonymous") //or anonymous user
                )
            ) {
                //user should login

                //close all waiter that will never close because we drop the response
                VeloxWebView.closeAllWaiters();

                if (app.isSuspended() === "login") {
                    //already suspended, don't do anything
                    return;
                }


                //suspend to login
                app.suspendNavigation("login", app.context.controllers.loginController);


                if (app.currentPosition) {
                    //display message to user
                    VeloxWebView.error(VeloxWebView.tr("login.disconnectLoginAgain"), function () {
                        app.context.controllers.loginController.focusLogin();
                    });
                }
                if(response.url === "refreshUser?"){
                    next() ;
                }
                return;
            }
            next();
        }
    },
    {
        type: "navigation", //called on navigation
        interceptor: function (app, currentPosition, next){
            if(app.context.api.currentUser){ return next() ; } //OK logged
            //not logged, open the login controller
            app.suspendNavigation("login", app.context.controllers.loginController, {}) ;
        }
    },
    {
        type: "startup", //called on startup
        interceptor: function (app, callback){
            //refresh user session from server
            app.context.api.refreshUser(function(err){
                if(err){ 
                    //not logged will be handled by ajax interceptor
                }
                callback() ;
            }) ;
        }
    }
];