/* global VeloxWebView, VeloxViewController */
var LoginController = VeloxViewController.create({
    directory : "views/login",
    name: "login",
    container: "main",
    noRouting: true
}) ;

LoginController.prototype.prepareDataOnEnter = function(data, callback){
    //normal login
    callback(null, {
        lang: VeloxWebView.i18n.getLang() 
    });
};

LoginController.prototype.onLang = function(){
    this.view.updateData(); 
    VeloxWebView.i18n.setLang(this.data.lang) ;
} ;

LoginController.prototype.focusLogin = function(){
    this.view.focusLogin() ;
};

LoginController.prototype.onConnect = function(){
    this.view.updateData(); 
    this.data.error = "" ;
    var errors = [] ;
    if(!this.data.connectLogin){
        errors.push(this.view.tr("login.pleaseInputLogin")) ;
    }
    if(!this.data.connectPassword){
        errors.push(this.view.tr("login.pleaseInputPassword")) ;
    }
    if(errors.length>0){
        this.data.error = errors.join("<br/>") ;
        return this.view.reload() ;
    }
    this.view.longTask(function(done){
        this._doConnect(this.data.connectLogin, this.data.connectPassword, done) ;
    }.bind(this)) ;
} ;

LoginController.prototype._doConnect = function(connectLogin, connectPassword, callback){
    this.api.auth.user(connectLogin, connectPassword, function(err){
        if(err){
            this.data.error = this.view.tr("login.cantLogin") ;
            this.view.reload() ;
            return callback() ;
        }
        //login OK
        this.view.reload() ; 

        this.resumeNavigation("/") ;
        return callback() ;
    }.bind(this)) ;
} ;

module.exports = new LoginController() ;