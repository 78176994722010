/**
 * This file contains all the bootstrap dependencies for datables
 * 
 * They will be loaded after datatables libs are loaded
 */

module.exports = [
    [
        {name: "datatables-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/$VERSION/css/dataTables.bootstrap4.min.css",
            npmPath: "datatables.net-bs4/css/dataTables.bootstrap4.css"
        }
    ],
    [
        {name: "datatables-autofill-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/autofill/2.2.2/css/autoFill.bootstrap4.css",
            npmPath: "datatables.net-autofill-bs4/css/autoFill.bootstrap4.min.css"
        },
        {name: "datatables-buttons-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/buttons/1.5.1/css/buttons.bootstrap4.min.css",
            npmPath: "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css"
        },
        {name: "datatables-colreorder-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/colreorder/1.4.1/css/colReorder.bootstrap4.min.css",
            npmPath: "datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css"
        },
        {name: "datatables-fixedcolumns-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/fixedcolumns/3.2.4/css/fixedColumns.bootstrap4.min.css",
            npmPath: "datatables.net-fixedcolumns-bs4/css/fixedColumns.bootstrap4.min.css"
        },
        {name: "datatables-fixedheader-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/fixedheader/3.1.3/css/fixedHeader.bootstrap4.min.css",
            npmPath: "datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css"
        },
        {name: "datatables-responsive-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/responsive/2.2.1/css/responsive.bootstrap4.min.css",
            npmPath: "datatables.net-responsive-bs4/css/responsive.bootstrap4.css"
        },
        {name: "datatables-rowgroup-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/rowgroup/1.0.2/css/rowGroup.bootstrap4.min.css",
            npmPath: "datatables.net-rowgroup-bs4/css/rowGroup.bootstrap4.css"
        },
        {name: "datatables-rowreorder-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/rowreorder/1.2.3/css/rowReorder.bootstrap4.min.css",
            npmPath: "datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css"
        },
        {name: "datatables-scroller-bs4",
            type: "css",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/scroller/1.4.4/css/scroller.bootstrap4.min.css",
            npmPath: "datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css"
        },
    ],
    [
        {name: "datatables-bs4-js",
            type: "js",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/$VERSION/js/dataTables.bootstrap4.min.js",
            npmPath: "datatables.net-bs4/js/dataTables.bootstrap4.js"
        },
    ],
    [
        {name: "datatables-autofill-bs4-js",
            type: "js",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/autofill/2.2.2/js/autoFill.bootstrap4.min.js",
            npmPath: "datatables.net-autofill-bs4/js/autoFill.bootstrap4.min.js"
        },
        {name: "datatables-buttons-bs4-js",
            type: "js",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/buttons/1.5.1/js/buttons.bootstrap4.min.js",
            npmPath: "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js"
        },
        {name: "datatables-responsive-bs4-js",
            type: "js",
            version: "1.10.16",
            cdn: "https://cdn.datatables.net/responsive/2.2.1/js/responsive.bootstrap4.min.js",
            npmPath: "datatables.net-responsive-bs4/js/responsive.bootstrap4.min.js"
        },
    ]
];