/*global VeloxViewController, VeloxWebView*/

var mainController = new VeloxViewController({
    directory : "views/main",
    name: "main",
    container: "main",
    route: "" //displayed by default (no route)
}) ;

mainController.prepareDataOnEnter = function(data, callback){
    return callback(null, {
        user: this.api.currentUser
    }) ;
} ;

mainController.onLogout = function(){
    this.view.longTask(function(done){
        this.api.logout(function(err){
            if(err){ return done(err);}
            this.navigate() ;
            mainController.refresh() ;
            done() ;
        }.bind(this)) ;
    }.bind(this));
} ;

mainController.onChangePassword = function(){
    this.view.longTask(function(done){
        var viewChange = new VeloxWebView("views/main", "changePassword") ;
        viewChange.openInPopup({
            title: VeloxWebView.tr("main.changePassword")
        }, function(){
            done() ;
        }.bind(this))  ;
        viewChange.on("validate",function(ev){
            var data = viewChange.getData() ;
            this.api.changeUserPassword(data.passwordOld, data.passwordNew, function(err, success){
                if(err){ return done(err);}
                if(!success){
                    return done(VeloxWebView.tr("main.wrongPassword")) ;
                }
                viewChange.close() ;
                VeloxWebView.info(VeloxWebView.tr("main.changePasswordSuccess"), done) ;
            }.bind(this)) ;
        }.bind(this)) ;
    }.bind(this)) ;
} ;



/* 
The main controller is special, it should never been hidden because it contains others views 
So we change the default stack/unstack behaviour to "do nothing"
*/
mainController.stack = function(){} ;
mainController.unstack = function(){} ;

module.exports = mainController ;