/**
 * Add endpoints of specific api calls in this file
 * 
 * for example : 
 *      {endpoint: "api/public/do_something", method: "POST", args: ["param1", "param2"] },
 * 
 * will be available in controllers as :
 *      this.api.public.do_something(param1, param2, function(err){...})
 */

module.exports = [
    //{endpoint: "api/public/do_something", method: "POST", args: ["param1", "param2"] },
] ;