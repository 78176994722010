/*global VeloxWebView*/

/**
 * @typedef UploadOptions
 * @type {object}
 * @property {string} [accept] The HTML file input field accept options (https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-accept)
 * @property {boolean} [multiple] Accept multi selection
 */

/**
 * Open upload popup
 * @param {UploadOptions} options upload options
 * @param {function} callback 
 */
function upload(options, callback){
    if(typeof(options) === "function"){
        options = {};
        callback = options;
    }
    var opts = JSON.parse(JSON.stringify(options||{})) ;

    var v = new VeloxWebView("views/util", "upload") ;
    v.openInPopup(function(){
        v.render({options: opts}) ;
    });
    v.on("validate", function(ev){
        callback(ev.data) ;
    }) ;
}

module.exports =  upload;