/*global VeloxGridAndFormController, VeloxWebView*/


/**
 * This is a basic MVC grid/form controller
 * 
 * It creates 2 routes : #user/list and #user/form
 */
var userController = new VeloxGridAndFormController("velox_user", {
    grid : {
        directory: "views/user",
        name: "userGrid",
    },
    form : {
        directory: "views/user",
        name: "userForm",
        defaultData : {
            auth_type: "password",
            active: true
        }
    }
});


module.exports = userController ;